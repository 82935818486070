<template>
  <div class="">
    <h1 class=" text-uppercase text-center title">Admin Dashboard</h1>
    <v-row justify="center">
      <v-col class="text-center" cols="12" sm="6">
        <v-img src="uploads/2.jpg" class="mx-auto"></v-img>
      </v-col>
      <v-col class="text-center" cols="12" sm="6" >
        <h1>Olawale Adekola</h1> 
        <h2>•Visual Storyteller</h2> 
        <h3>Telling Visual stories for memories to live on.</h3>
<h5>🕹Currently documenting the Attorney General, Ekiti State @theolawalefapohunda</h5>
<p>Olawale Adekola is an International Award winning Documentary/Portrait  photographer and Filmmaker living in Lagos, Nigeria. Olawale's interest in photography is to be a keen observer of humanity by capturing and retelling stories from perspectives that are often overlooked.</p>

<p>Olawale is always willing to take new challenges in documenting stories that capture the emotion of people and their everyday activities in their environments within and outside Nigeria. Currently documenting the Office of the Attorney General and Commissioner for Justice in Ekiti State is a step closer to reaching a dream goal.</p>

<p>Olawale’s images and works have been utilised and featured in Wikipedia (Images of the First Storey Building in Nigeria). His presence at the Nikon 100th anniversary in 2017 won him the prize of the day as well.</p>

<p>Over the years Olawale Adekola has won various photography awards including a Top 20 Nigeria Photographers in an international competition with over 103,000 entries from over 160 countries putting a stamp on his chosen photography specialties which includes but not limited to photo-journalism, fashion editorial, documentary, event, commercials and art portraits. </p>

<p>Olawale was selected as one of Thirty (30) photographers trained in Lagos, Nigeria at the Canon Storytelling Masterclass organized under Canon’s Miraisha program and in collaboration with the African Artists’ Foundation 2019.</p>  

<p>When Olawale is not on assignment, he works on personal projects.</p> 

      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from "axios";

// const APIURL = ''

export default {
  name: "Admin",
};
</script>
